import React, { useState } from "react";
import moment from "moment";
import { PureModal, PageHeader } from "components/ui";
import UploadDocumentForm from "./upload-document";
import { IoIosCheckmark, IoIosClose } from "react-icons/lib/io";

const TableRow = React.memo(
  ({
    hasAppPermission = false,
    row,
    handleReplace,
    handleRename,
    handleRemove
  }) => {
    const { DocumentId, DocumentTitle, LastModifiedDate } = row;

    const [renaming, setRenaming] = useState(false);
    const [newTitle, setNewTitle] = useState(DocumentTitle);

    const onRename = () => {
      setRenaming(false);
      handleRename({ id: DocumentId, newTitle: newTitle });
    };

    return (
      <tr>
        <td>
          {renaming && hasAppPermission ? (
            <div style={{ display: "flex", gap: "5px" }}>
              <input
                type="text"
                value={newTitle}
                onChange={e => setNewTitle(e.target.value)}
              />
              <IoIosCheckmark
                style={{
                  color: "#4281A6",
                  fontSize: "2rem",
                  cursor: "pointer"
                }}
                onClick={onRename}
              />
              <IoIosClose
                style={{
                  color: "#4281A6",
                  fontSize: "2rem",
                  cursor: "pointer"
                }}
                onClick={() => setRenaming(false)}
              />
            </div>
          ) : (
            DocumentTitle
          )}
        </td>
        <td>{moment(LastModifiedDate).format("MM/DD/YYYY")}</td>
        <td>
          {hasAppPermission && (
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <button
                style={{ padding: "5px", margin: 0 }}
                onClick={() => setRenaming(true)}
              >
                Rename
              </button>
              <PureModal
                renderTrigger={({ openModal }) => (
                  <button
                    style={{ padding: "5px", margin: 0 }}
                    onClick={openModal}
                  >
                    Replace
                  </button>
                )}
                renderContent={({ closeModal }) => (
                  <UploadDocumentForm
                    id={DocumentId}
                    closeModal={closeModal}
                    handlePostUpdateDocument={({ document_file }) => {
                      let formData = new FormData();
                      formData.append("file", document_file);
                      handleReplace({
                        id: DocumentId,
                        file: formData
                      });
                    }}
                  />
                )}
              />
              <PureModal
                renderTrigger={({ openModal }) => (
                  <button
                    style={{ padding: "5px", margin: 0 }}
                    onClick={openModal}
                  >
                    Delete
                  </button>
                )}
                renderContent={({ closeModal }) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                      marginTop: 20,
                      gap: "5px",
                      margin: "10px"
                    }}
                  >
                    <PageHeader title={"Delete Document"} />
                    <h4>Are you sure you want to delete this document?</h4>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        gap: "5px"
                      }}
                    >
                      <button
                        onClick={() => {
                          handleRemove(DocumentId);
                          closeModal();
                        }}
                      >
                        Yes
                      </button>
                      <button onClick={closeModal}>No</button>
                    </div>
                  </div>
                )}
              />
            </div>
          )}
        </td>
      </tr>
    );
  }
);

export default TableRow;
