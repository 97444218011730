import React, { useEffect } from "react";
import { PageHeader, TableButton } from "components/ui";
import IoMdRefresh from "react-icons/lib/io/refresh";
import { get } from "utils/api";
import { useFetch } from "hooks";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import { errorMessage } from "actions/message";
import { useDispatch } from "react-redux";
import DocumentHubTableRow from "./document-hub-table-row";

const DocumentHub = ({}) => {
  const dispatch = useDispatch();

  const getCompanyDocumentHubAPI = useFetch({
    apiFn: () => get(`document_hub`),
    defaultValue: [],
    transformResponse: response => response.Documents,
    onError: () => {
      dispatch(errorMessage("Sorry, Something went wrong"));
    }
  });

  const { rows, query, queryDispatcher } = useLocalQuery(
    getCompanyDocumentHubAPI.response,
    {
      sort: { by: "DocumentTitle", direction: "asc" },
      filters: {}
    }
  );

  useEffect(() => {
    getCompanyDocumentHubAPI.fetchData();
  }, []);

  return (
    <div>
      <PageHeader title="Document Hub" />
      <Table
        TableRow={row => {
          return <DocumentHubTableRow row={row} />;
        }}
        containerClass={"query-table document-hub-table "}
        columns={[
          {
            header: "Document Title",
            field: "DocumentTitle"
          },
          {
            header: "Company",
            field: "Company"
          },
          {
            header: "Last Modified Date",
            field: "LastModifiedDate"
          }
        ]}
        query={query}
        queryDispatcher={queryDispatcher}
        rows={rows}
        rowKey="id"
        isFetching={getCompanyDocumentHubAPI.isFetching}
        controls={
          <div className="query-table-controls">
            <TableButton
              onClick={() => {
                getCompanyDocumentHubAPI.fetchData();
              }}
              disabled={getCompanyDocumentHubAPI.isFetching}
            >
              <IoMdRefresh />
            </TableButton>
          </div>
        }
      />
    </div>
  );
};

export default DocumentHub;
