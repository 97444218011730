import React from "react";
import moment from "moment";
import DropdownComplianceBarChartFilter from "components/ui/dropdown/dropdown-compliance-bar-chart-filter";
import { PureModal } from "components/ui";

const DocumentHubTableRow = React.memo(({ row }) => {
  const { DocumentTitle, LastModifiedDate, DownloadFileURL, Company } = row;

  return (
    <tr>
      <td>
        <div>
          <DropdownComplianceBarChartFilter
            trigger={<span>{DocumentTitle}</span>}
          >
            <ul className="dropdown-list">
              <li className="dropdown-command" onClick={() =>  document.getElementById("view-doc").click()}>
                <PureModal
                  renderTrigger={({ openModal }) => (
                    <div
                      onClick={() => openModal()}
                      style={{ color: "#4281A6" }}
                      id="view-doc"
                    >
                      Open
                    </div>
                  )}
                  renderContent={({ closeModal }) => (
                    <div>
                      <iframe
                        src={DownloadFileURL}
                        style={{ width: "100%", height: "500px" }}
                      ></iframe>
                    </div>
                  )}
                />
              </li>
              <li className="dropdown-command">
                <a href={DownloadFileURL} target="blank">
                  Open in New Window
                </a>
              </li>
            </ul>
          </DropdownComplianceBarChartFilter>
        </div>
      </td>
      <td>{Company}</td>
      <td>{moment(LastModifiedDate).format("MM/DD/YYYY")}</td>
    </tr>
  );
});

export default DocumentHubTableRow;
