import React from "react";
import { Formik, Form, ErrorMessage } from "formik";
import FormikField from "components/forms/formik-field";
import { InlineWaiting, PageHeader } from "components/ui";
import * as Yup from "yup";

const UploadDocumentForm = ({ id, handlePostUpdateDocument, closeModal }) => {
  return (
    <div>
      <div>
        <PageHeader title={id ? "Replace Document" : "Upload New Document"} />
      </div>
      <Formik
        initialValues={{
          document_title: "",
          document_file: null
        }}
        validationSchema={Yup.object().shape({
          document_title: Yup.string().required("Required")
        })}
        enableReinitialize={true}
        validate={({ document_file }) => {
          const errors = {};
          if (!document_file) {
            errors.document_file = "A PDF file is required";
          } else if (
            document_file &&
            document_file.type !== "application/pdf"
          ) {
            errors.document_file = "The selected document should be a PDF";
          }
          return errors;
        }}
        onSubmit={async ({ document_title, document_file }) => {
          if (id) {
            await handlePostUpdateDocument({ document_file });
          } else {
            await handlePostUpdateDocument({
              document_title,
              document_file
            });
          }

          closeModal();
        }}
      >
        {({ values, setFieldValue, isSubmitting, isValid, validateField }) => (
          <Form
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 10
            }}
          >
            {values.document_file && !id && (
              <FormikField
                name="document_title"
                label="Document Title"
                type="text"
              />
            )}
            <label htmlFor="document_file">Upload PDF</label>

            <input
              className="document-file-hub"
              id="document_file"
              name="document_file"
              type="file"
              accept="application/pdf"
              onChange={async event => {
                const file = event.currentTarget.files[0];
                if (file) {
                  await setFieldValue("document_file", file);
                  const nameWithoutExtension = file.name
                    .split(".")
                    .slice(0, -1)
                    .join(".");
                  await setFieldValue("document_title", nameWithoutExtension);
                  await validateField("document_title");
                }else{
                  await setFieldValue("document_file", null);
                }
              }}
            />
            <ErrorMessage
              name="document_file"
              component="div"
              style={{ color: "red" }}
            />

            {isSubmitting ? (
              <InlineWaiting />
            ) : (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "flex-end",
                  marginTop: 20,
                  gap: "5px"
                }}
              >
                <button
                  style={{ margin: 0 }}
                  type="button"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button style={{ margin: 0 }} type="submit" disabled={!isValid}>
                  Submit
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UploadDocumentForm;
