import React, { useEffect, useState } from "react";
import {
  PageHeader,
  TableButton,
  PureModal,
  InlineWaiting
} from "components/ui";
import IoMdRefresh from "react-icons/lib/io/refresh";
import { get, put, post, del } from "utils/api";
import { useFetch } from "hooks";
import useLocalQuery, { Table } from "hooks/useQuery/local-query";
import DocumentHubTableRow from "./table-row";
import UploadDocumentForm from "./upload-document";
import { message, errorMessage } from "actions/message";
import { useDispatch } from "react-redux";

const CompanyDocumentHub = ({ params: { id: companyId }, title }) => {
  const MAX_UPLOADS = 10;
  const defaultColumns = [
    {
      header: "Document Title",
      field: "DocumentTitle"
    },
    {
      header: "Last Modified Date",
      field: "LastModifiedDate"
    }
  ];
  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();

  const {
    response: companyAppPermissions,
    fetchData: fetchCompanyAppPermissions,
    isFetching: fetchingCompanyAppPermissions
  } = useFetch({
    apiFn: () => get(`companies/${companyId}/app_permissions`),
    defaultValue: {}
  });

  useEffect(() => {
    if (companyId) fetchCompanyAppPermissions();
  }, []);

  useEffect(() => {
    if (companyAppPermissions?.document_hub)
      setColumns([
        ...defaultColumns,
        {
          header: "Actions",
          field: "actions"
        }
      ]);
  }, [companyAppPermissions]);

  const getCompanyDocumentHubAPI = useFetch({
    apiFn: () => get(`document_hub/${companyId}`),
    defaultValue: [],
    transformResponse: response => response.Documents,
    onError: () => {
      dispatch(errorMessage("Sorry, Something went wrong"));
    }
  });

  const createCompanyDocumentAPI = useFetch({
    apiFn: payload => post(`document_hub/${companyId}`, payload),
    defaultValue: {},
    onSuccess: () => {
      getCompanyDocumentHubAPI.fetchData();
      dispatch(message("New Document Created"));
    },
    onError: () => {
      dispatch(errorMessage("Sorry, Something went wrong"));
    }
  });

  const replaceCompanyDocumentAPI = useFetch({
    apiFn: payload => put(`document_hub/${payload.id}/replace`, payload.file),
    defaultValue: {},
    onSuccess: () => {
      getCompanyDocumentHubAPI.fetchData();
      dispatch(message("Document Replaced"));
    },
    onError: () => {
      dispatch(errorMessage("Sorry, Something went wrong"));
    }
  });

  const renameCompanyDocumentAPI = useFetch({
    apiFn: payload => put(`document_hub/${payload.id}/${payload.newTitle}`),
    defaultValue: {},
    onSuccess: () => {
      getCompanyDocumentHubAPI.fetchData();
      dispatch(message("Document Renamed"));
    },
    onError: () => {
      dispatch(errorMessage("Sorry, Something went wrong"));
    }
  });

  const removeCompanyDocumentAPI = useFetch({
    apiFn: payload => del(`document_hub/${payload}`),
    defaultValue: {},
    onSuccess: () => {
      getCompanyDocumentHubAPI.fetchData();
      dispatch(message("Document was Deleted"));
    },
    onError: () => {
      dispatch(errorMessage("Sorry, Something went wrong"));
    }
  });

  useEffect(() => {
    getCompanyDocumentHubAPI.fetchData();
  }, []);

  const { rows, query, queryDispatcher } = useLocalQuery(
    getCompanyDocumentHubAPI.response,
    {
      sort: { by: "DocumentTitle", direction: "asc" },
      filters: {}
    }
  );

  return (
    <div className="document-hub-table-container">
      {title && (
        <PageHeader title={title}>
          {!fetchingCompanyAppPermissions ? (
            companyAppPermissions?.document_hub && (
              <PureModal
                renderTrigger={({ openModal }) => (
                  <>
                    <button
                      onClick={openModal}
                      disabled={rows.length >= MAX_UPLOADS}
                    >
                      Upload new document
                    </button>
                    {rows.length >= MAX_UPLOADS && (
                      <h6 style={{ color: "red" }}>
                        You cannot upload more than 10 documents
                      </h6>
                    )}
                  </>
                )}
                renderContent={({ closeModal }) => (
                  <UploadDocumentForm
                    closeModal={closeModal}
                    handlePostUpdateDocument={({
                      document_title,
                      document_file
                    }) => {
                      let formData = new FormData();
                      formData.append("title", document_title);
                      formData.append("file", document_file);
                      createCompanyDocumentAPI.fetchData(formData);
                    }}
                  />
                )}
              />
            )
          ) : (
            <InlineWaiting />
          )}
        </PageHeader>
      )}
      <div>
        <Table
          TableRow={row => {
            return (
              <DocumentHubTableRow
                row={row}
                handleReplace={replaceCompanyDocumentAPI.fetchData}
                handleRename={renameCompanyDocumentAPI.fetchData}
                handleRemove={removeCompanyDocumentAPI.fetchData}
                hasAppPermission={companyAppPermissions?.document_hub}
              ></DocumentHubTableRow>
            );
          }}
          containerClass={"query-table document-hub-table "}
          columns={columns}
          query={query}
          queryDispatcher={queryDispatcher}
          rows={rows}
          rowKey="id"
          isFetching={
            getCompanyDocumentHubAPI.isFetching ||
            createCompanyDocumentAPI.isFetching ||
            replaceCompanyDocumentAPI.isFetching ||
            renameCompanyDocumentAPI.isFetching ||
            removeCompanyDocumentAPI.isFetching ||
            fetchingCompanyAppPermissions
          }
          controls={
            <div className="query-table-controls">
              <TableButton
                onClick={() => {
                  getCompanyDocumentHubAPI.fetchData();
                }}
                disabled={getCompanyDocumentHubAPI.isFetching}
              >
                <IoMdRefresh />
              </TableButton>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CompanyDocumentHub;
